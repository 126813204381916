






import Vue from 'vue'
import StaffDashboard from '../../views/staffdashboard/StaffDashboard.vue'
export default Vue.extend({
    name: "StaffDashboardIndex",
    components: {
        StaffDashboard
    },
})
