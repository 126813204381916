






import Vue from 'vue';
import StaffDashboard from '../../components/staffdashboard/StaffDashboard.vue';
export default Vue.extend({
  name: 'StaffDashboardPage',
  components: {
    StaffDashboard,
  },
});
