








































































































import DoughnutChart from './DoughnutChart.vue';
import mixins from '@/utils/mixins';
import auth from '@/mixins/auth';
import { mapActions, mapGetters } from 'vuex';
import { ErrorManager } from '@/models/error';
import FranchiseFilter from '../stafffranchisemanager/FranchiseFilter.vue';
import { IFetchShipmentParams } from '@/models/shipment';
import { IFetchStatsSummaryParams } from '@/services/statistics/service';
import { Franchise } from '@/models/franchise';

export default mixins(auth).extend({
  name: 'StaffDashboard',
  components: {
    DoughnutChart,
    FranchiseFilter
  },
  data() {
    return {
      userDataCollection: null as any,
      packageDataCollection: null as any,
      errorMessage: '',
      loading: false,
      userAdmin: 1,
      franchiseActiveView: { value: null, text: 'All' } as number | any
    };
  },
  computed: {
    ...mapGetters({
      statistics: 'statistics/getStatistics',
      outstandingBalance: 'statistics/getOutstandingBalance'
    }),

    userID(): number {
      const userInfo = JSON.parse(localStorage.getItem('user')!);
      const userId = userInfo == null ? null : userInfo.pk;
      return userId;
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    franchise(): Franchise {
      return this.getUserFranchise;
    },

    revenueInfo(): any {
      return [
        {
          icon: 'mdi-currency-usd',
          title: 'Total Revenue',
          amount: this.statistics.total_revenue.toFixed(2)
        },
        {
          icon: 'mdi-currency-usd-off',
          title: 'Outstanding Balance',
          amount: this.outstandingBalance.toFixed(2)
        }
      ];
    },

    cardInfo(): any {
      if (!this.statistics.user_types) return;
      return [
        {
          icon: 'mdi-account-group',
          title: 'Customers',
          amount: this.statistics.user_types.customers
        },
        {
          icon: 'mdi-account-tie',
          title: 'Employees',
          amount: this.statistics.user_types.employees
        },
        {
          icon: 'mdi-shield-crown',
          title: 'Admins',
          amount: this.statistics.user_types.admins
        },
        {
          icon: 'mdi-gift',
          title: 'Assigned Packages',
          amount: this.statistics.packages_assigned
        },
        {
          icon: 'mdi-gift-off',
          title: 'Unassigned Packages',
          amount: this.statistics.packages_unassigned
        }
      ];
    }
  },

  mounted() {
    this.getFranchiseStats();
  },

  methods: {
    ...mapActions({
      fetchStatisticsSummary: 'statistics/fetchStatisticsSummary',
      fetchStatisticsOutstandingBalance:
        'statistics/fetchStatisticsOutstandingBalance',
      fetchAccount: 'account/fetchAccount'
    }),

    async getFranchiseStats() {
      this.loading = true;

      try {
        const franchisePk = await this.tryGetOrFetchFranchisePk();
        await this.fetchStatisticsOutstandingBalance({
          franchise: franchisePk
        } as IFetchShipmentParams);
        await this.fetchStatisticsSummary({
          franchise: franchisePk
        } as IFetchStatsSummaryParams);

        this.fillData();
      } catch (error) {
        this.errorMessage = ErrorManager.extractApiError(error);
      } finally {
        this.loading = false;
      }
    },

    async tryGetOrFetchFranchisePk() {
      let pk = null;
      try {
        if (this.isSuperAdmin) {
          pk = this.franchiseActiveView.value;
        } else {
          if (!this.franchise) {
            await this.fetchAccount(this.userID);
          }
          pk = this.franchise.pk;
        }
        return pk;
      } catch (error) {
        this.errorMessage = ErrorManager.extractApiError(error);
      }
    },

    fillData() {
      this.userDataCollection = {
        labels: ['Customers', 'Employees', 'Admins'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: ['#3492CF', '#4CAF50', '#FF5252'],
            data: [
              this.statistics.user_types.customers,
              this.statistics.user_types.employees,
              this.statistics.user_types.admins
            ]
          }
        ]
      };
      this.packageDataCollection = {
        labels: ['Assigned', 'Unassigned'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: ['#3492CF', '#FF5252'],
            data: [
              this.statistics.packages_assigned,
              this.statistics.packages_unassigned
            ]
          }
        ]
      };
    }
  }
});
