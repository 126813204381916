




























import Proxyable from '@/mixins/proxyable';
import { Franchise } from '@/models/franchise';
import { mapActions, mapState } from 'vuex';

export default Proxyable.extend({
  data() {
    return {
      defaultObj: { value: null, text: 'All' }
    };
  },
  computed: {
    ...mapState('franchise', ['userFranchise']),

    customAttrs(): Record<string, unknown> {
      return this.$attrs;
    },

    franchiseList(): any {
      const list = this.userFranchise.map((data: Franchise) => {
        return {
          value: data.pk,
          text: data.name
        };
      });

      list.unshift(this.defaultObj);
      return list;
    }
  },

  methods: {
    ...mapActions({
      fetchUserFranchises: 'franchise/fetchUserFranchises'
    })
  },
  async mounted() {
    await this.fetchUserFranchises();
    this.internalValue = this.defaultObj;
  }
});
